import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

import { confirmRegister } from "../utils/auth"
import { navigate } from "gatsby"
import { Spinner } from "evergreen-ui"
import useAuth from "../hooks/useAuth"
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai"
import { colors } from "../components/styles"

export const LoadingContainer = styled.div`
  margin: auto;
  max-width: 400px;
  margin-top: 10vh;
  display: flex;
  align-items: center;
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 7px #00000008;
`

export const TitleLoading = styled.span`
  font-size: 1rem;
  margin-left: 20px;
`

export default ({location}) => {
  const [isError, setError] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const {setUserSession} = useAuth()

  useEffect(() => {
    if (location) {
      let code = new URLSearchParams(location.search).get('code')
      let username = new URLSearchParams(location.search).get('user')
      let userID = new URLSearchParams(location.search).get('id')
      if (code && username && userID) {
        confirmRegister(username, code)
          .then(() => {
            setSuccess(true)
            setTimeout(() => navigate(`/login`), 4000)
          })
          .catch(err => {
            console.error(err)
            setError(err.code)})
      }
      else setError(true)
    }
  }, [location.search])

  return (
    <LoadingContainer>
      {!isSuccess && !isError &&
      <>
        <Spinner marginX="auto" size={38} color={"black"}/>
        <TitleLoading>Confirmation de votre compte en cours...</TitleLoading>
      </>
      }

      {isSuccess &&
      <>
        <AiOutlineCheck size={45} color={"#1dd1a1"}/>
        <TitleLoading>Votre compte à bien été confirmé.</TitleLoading>
      </>
      }

      {isError &&
      <>
        <AiOutlineClose size={45} color={colors.warning}/>
        {isError === "NotAuthorizedException" ? <TitleLoading>Votre compte est déjà confirmé.</TitleLoading> :
          <TitleLoading>Votre compte n'a pu être confirmé, merci de bien vouloir nous contacter.</TitleLoading>
        }
      </>
      }

    </LoadingContainer>
  )
}
